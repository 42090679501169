import {ContactFormData, NewsletterData} from "../types/forms.ts";


const API_BASE_URL = import.meta.env.VITE_API_URL || 'http://localhost:8000';

export async function submitContact(data: ContactFormData): Promise<Response> {
    const response = await fetch(`${API_BASE_URL}/api/contact`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error('Failed to submit contact form');
    }

    return response;
}

export async function subscribeNewsletter(data: NewsletterData): Promise<Response> {
    const response = await fetch(`${API_BASE_URL}/api/newsletter`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error('Failed to subscribe to newsletter');
    }

    return response;
}
