import React, { useState } from 'react';
import { Mail } from 'lucide-react';
import { subscribeNewsletter } from '../services/api';
import {NewsletterData, newsletterSchema} from "../types/forms.ts";
import {z} from "zod";

interface NewsletterProps {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    className?: string;
    title?: string;
    description?: string;
}

const Newsletter: React.FC<NewsletterProps> = ({
                                                   onSuccess,
                                                   onError,
                                                   className = '',
                                                   title = 'Newsletter',
                                                   description = 'Subscribe to our newsletter for the latest updates, news, and exclusive content.'
                                               }) => {
    const [formData, setFormData] = useState<NewsletterData>({
        name: '',
        email: ''
    });
    const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
    const [errors, setErrors] = useState<Record<string, string>>({});

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setStatus('loading');
        setErrors({});

        try {
            const validatedData = newsletterSchema.parse(formData);
            await subscribeNewsletter(validatedData);
            setStatus('success');
            setFormData({ name: '', email: '' });
            onSuccess?.();
            setTimeout(() => setStatus('idle'), 3000);
        } catch (error) {
            setStatus('error');
            if (error instanceof z.ZodError) {
                const fieldErrors: Record<string, string> = {};
                error.errors.forEach((err) => {
                    if (err.path[0]) {
                        fieldErrors[err.path[0].toString()] = err.message;
                    }
                });
                setErrors(fieldErrors);
            }
            onError?.(error instanceof Error ? error : new Error('An error occurred'));
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    return (
        <div className={`bg-white rounded-xl shadow-lg p-6 md:p-8 ${className}`} style={{ maxWidth: '600px', margin: '20px auto'}}>
            <div className="flex items-center gap-3 mb-6">
                <Mail className="w-6 h-6 text-indigo-600" />
                <h2 className="text-2xl font-semibold text-gray-900">{title}</h2>
            </div>
            <p className="text-gray-600 mb-6">{description}</p>
            <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                    <div>
                        <label htmlFor="newsletter-name" className="block text-sm font-medium text-gray-700 mb-1">
                            Name
                        </label>
                        <input
                            type="text"
                            id="newsletter-name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Your name"
                            required
                            disabled={status === 'loading'}
                            className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition ${
                                errors.name ? 'border-red-500' : 'border-gray-300'
                            }`}
                        />
                        {errors.name && (
                            <p className="mt-1 text-sm text-red-600">{errors.name}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="newsletter-email" className="block text-sm font-medium text-gray-700 mb-1">
                            Email
                        </label>
                        <input
                            type="email"
                            id="newsletter-email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="your@email.com"
                            required
                            disabled={status === 'loading'}
                            className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition ${
                                errors.email ? 'border-red-500' : 'border-gray-300'
                            }`}
                        />
                        {errors.email && (
                            <p className="mt-1 text-sm text-red-600">{errors.email}</p>
                        )}
                    </div>
                    <button
                        type="submit"
                        disabled={status === 'loading'}
                        className="w-full bg-indigo-600 text-white py-3 px-6 rounded-lg hover:bg-indigo-700 transition duration-200 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        {status === 'loading' ? (
                            'Subscribing...'
                        ) : (
                            <>
                                Subscribe
                                <Mail className="w-5 h-5" />
                            </>
                        )}
                    </button>
                </div>
            </form>

            {status === 'success' && (
                <div className="mt-4 bg-green-50 text-green-800 rounded-lg p-4 text-center">
                    Successfully subscribed!
                </div>
            )}

            {status === 'error' && Object.keys(errors).length === 0 && (
                <div className="mt-4 bg-red-50 text-red-800 rounded-lg p-4 text-center">
                    Failed to subscribe. Please try again.
                </div>
            )}
        </div>
    );
};

export default Newsletter;
