import { Code } from 'lucide-react';
import CodeBlock from './components/CodeBlock';

function App() {
    const scriptUrl = window.location.origin + '/embed.js';

    const contactEmbedCode = `<div id="contact-form-container"></div>
<script src="${scriptUrl}"></script>
<script>
  window.embedContactForm('contact-form-container');
</script>`;

    const newsletterEmbedCode = `<div id="newsletter-container"></div>
<script src="${scriptUrl}"></script>
<script>
  window.embedNewsletter('newsletter-container');
</script>`;

    return (
        <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-white to-purple-50">
            <div className="container mx-auto px-4 py-12">
                <div className="max-w-4xl mx-auto">
                    <header className="text-center mb-12">
                        <h1 className="text-4xl font-bold text-gray-900 mb-4">Embeddable Forms</h1>
                        <p className="text-lg text-gray-600">Beautiful, responsive forms ready to embed in your website</p>
                    </header>

                    <div className="grid md:grid-cols-2 gap-8 mb-16">
                        <div className="space-y-8">
                            <h2 className="text-2xl font-semibold text-gray-900">Contact Form</h2>
                            <CodeBlock code={contactEmbedCode} />
                            <div className="bg-blue-50 text-blue-800 rounded-lg p-4">
                                <h4 className="font-semibold mb-2">Preview:</h4>
                                <div id="contact-form-container"></div>
                            </div>
                        </div>

                        <div className="space-y-8">
                            <h2 className="text-2xl font-semibold text-gray-900">Newsletter Form</h2>
                            <CodeBlock code={newsletterEmbedCode} />
                            <div className="bg-blue-50 text-blue-800 rounded-lg p-4">
                                <h4 className="font-semibold mb-2">Preview:</h4>
                                <div id="newsletter-container"></div>
                            </div>
                        </div>
                    </div>

                    <section className="bg-white rounded-xl shadow-lg p-6 md:p-8">
                        <div className="flex items-center gap-3 mb-6">
                            <Code className="w-6 h-6 text-indigo-600" />
                            <h2 className="text-2xl font-semibold text-gray-900">Integration Guide</h2>
                        </div>

                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-gray-800">How to Use</h3>
                            <ol className="list-decimal list-inside space-y-2 text-gray-700">
                                <li>Copy the embed code for the form you want to add</li>
                                <li>Paste it into your website's HTML where you want the form to appear</li>
                                <li>The form will automatically adapt to your website's layout</li>
                                <li>You can style the container div to control the form's width and position</li>
                            </ol>

                            <div className="mt-6">
                                <h3 className="text-lg font-semibold text-gray-800 mb-2">Event Handling</h3>
                                <pre className="bg-gray-50 p-4 rounded-lg text-sm">
                  {`// Listen for form submissions
document.getElementById('contact-form-container')
  .addEventListener('contactSubmitted', (e) => {
    console.log('Form submitted:', e.detail);
  });

// Listen for newsletter subscriptions
document.getElementById('newsletter-container')
  .addEventListener('newsletterSubmitted', (e) => {
    console.log('Newsletter submitted:', e.detail);
  });`}
                </pre>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default App;
