import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App.tsx';
import ContactEmbed from './routes/ContactEmbed';
import NewsletterEmbed from './routes/NewsletterEmbed';
import './index.css';

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
    },
    {
        path: '/embed/contact',
        element: <ContactEmbed />,
    },
    {
        path: '/embed/newsletter',
        element: <NewsletterEmbed />,
    },
]);

createRoot(document.getElementById('root')!).render(
    <StrictMode>
        <RouterProvider router={router} />
    </StrictMode>
);
