import React, { useEffect, useRef } from 'react';
import Newsletter from '../components/NewsletterForm';

const NewsletterEmbed: React.FC = () => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateHeight = () => {
            if (containerRef.current) {
                window.parent.postMessage({
                    type: 'resize',
                    height: containerRef.current.offsetHeight
                }, '*');
            }
        };

        // Update height on mount and when content changes
        updateHeight();
        const observer = new ResizeObserver(updateHeight);
        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);

    return (
        <div ref={containerRef}>
            <Newsletter
                onSuccess={() => window.parent.postMessage({ type: 'newsletterSubmitted', status: 'success' }, '*')}
                onError={(error) => window.parent.postMessage({
                    type: 'newsletterSubmitted',
                    status: 'error',
                    message: error.message
                }, '*')}
            />
        </div>
    );
};

export default NewsletterEmbed;
