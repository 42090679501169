import React, { useState } from 'react';
import { Send } from 'lucide-react';
import { submitContact } from '../services/api';
import {ContactFormData, contactFormSchema} from "../types/forms.ts";
import {z} from "zod";

interface ContactFormProps {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    className?: string;
}

const ContactForm: React.FC<ContactFormProps> = ({
                                                     onSuccess,
                                                     onError,
                                                     className = ''
                                                 }) => {
    const [formData, setFormData] = useState<ContactFormData>({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
    const [errors, setErrors] = useState<Record<string, string>>({});

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setStatus('loading');
        setErrors({});

        try {
            const validatedData = contactFormSchema.parse(formData);
            await submitContact(validatedData);
            setStatus('success');
            setFormData({ name: '', email: '', subject: '', message: '' });
            onSuccess?.();
            setTimeout(() => setStatus('idle'), 3000);
        } catch (error) {
            setStatus('error');
            if (error instanceof z.ZodError) {
                const fieldErrors: Record<string, string> = {};
                error.errors.forEach((err) => {
                    if (err.path[0]) {
                        fieldErrors[err.path[0].toString()] = err.message;
                    }
                });
                setErrors(fieldErrors);
            }
            onError?.(error instanceof Error ? error : new Error('An error occurred'));
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    return (
        <form
            onSubmit={handleSubmit}
            className={`bg-white rounded-xl shadow-lg p-6 md:p-8 ${className}`}
        >
            <div className="space-y-6">
                <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition ${
                            errors.name ? 'border-red-500' : 'border-gray-300'
                        }`}
                        placeholder="Your name"
                        disabled={status === 'loading'}
                    />
                    {errors.name && (
                        <p className="mt-1 text-sm text-red-600">{errors.name}</p>
                    )}
                </div>

                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition ${
                            errors.email ? 'border-red-500' : 'border-gray-300'
                        }`}
                        placeholder="your@email.com"
                        disabled={status === 'loading'}
                    />
                    {errors.email && (
                        <p className="mt-1 text-sm text-red-600">{errors.email}</p>
                    )}
                </div>

                <div>
                    <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                        Subject
                    </label>
                    <input
                        type="text"
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                        className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition ${
                            errors.subject ? 'border-red-500' : 'border-gray-300'
                        }`}
                        placeholder="How can we help?"
                        disabled={status === 'loading'}
                    />
                    {errors.subject && (
                        <p className="mt-1 text-sm text-red-600">{errors.subject}</p>
                    )}
                </div>

                <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                        Message
                    </label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows={4}
                        className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition ${
                            errors.message ? 'border-red-500' : 'border-gray-300'
                        }`}
                        placeholder="Your message..."
                        disabled={status === 'loading'}
                    />
                    {errors.message && (
                        <p className="mt-1 text-sm text-red-600">{errors.message}</p>
                    )}
                </div>

                <button
                    type="submit"
                    disabled={status === 'loading'}
                    className="w-full bg-indigo-600 text-white py-3 px-6 rounded-lg hover:bg-indigo-700 transition duration-200 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    {status === 'loading' ? (
                        'Sending...'
                    ) : (
                        <>
                            <Send className="w-5 h-5" />
                            Send Message
                        </>
                    )}
                </button>

                {status === 'success' && (
                    <div className="bg-green-50 text-green-800 rounded-lg p-4 text-center">
                        Message sent successfully!
                    </div>
                )}

                {status === 'error' && Object.keys(errors).length === 0 && (
                    <div className="bg-red-50 text-red-800 rounded-lg p-4 text-center">
                        Failed to send message. Please try again.
                    </div>
                )}
            </div>
        </form>
    );
};

export default ContactForm;
